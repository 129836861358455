body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  object-fit: cover;
  height: auto;
  display: block;
  margin: 20px auto;
  width: 100%;
}

/* 單圖 */
.singleImgWrap > img {
  object-fit: cover;
  height: auto;
  display: block;
  margin: 20px auto;
  width: 100%;
}

/* Image Picker */
.sd-imagepicker__image {
  max-width: 200px !important;
  max-height: 200px !important;
  cursor: pointer;
}

@media (max-width: 992px) {
  img {
    width: 100%;
    margin: 10px auto;
  }
}

.sd-logo__image {
  object-fit: none;
  width: 100%;
  height: auto;
}

/* 問卷大標置中 */
.sd-root-modern.sd-root-modern--mobile .sd-title.sd-container-modern__title {
  text-align: center;
}

/* LOGO 跟問卷大標 */
.sd-title.sd-container-modern__title {
  flex-direction: column;
}

.sd-header__text .sd-title {
  font-size: 28px !important;
}

/* 下一頁按鈕 */
.sd-action-bar {
  justify-content: center;
}

/* 開始按鈕 */
.sd-navigation__start-btn {
  background-color: var(
    --sjs-primary-backcolor,
    var(--primary, #19b394)
  ) !important;
  color: var(
    --sjs-primary-forecolor,
    var(--primary-foreground, #fff)
  ) !important;
}

:root {
  /* --primary: #fdd034; */
  /* --primary: #e8502e; */
  --primary: #19b394;
  --background: #ffffff;
  --background-dim: #f3f3f3;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #fff;
  --foreground: #10130c;
  --base-unit: 8px;
}

.sd-element__title .sv-string-viewer,
.sd-question__description,
.sd-description {
  white-space: pre-wrap !important;
}

.sd-question__description,
.sd-description {
  margin-top: 20px !important;
}

/* 活動已截止的屏蔽按鈕 */
.home .sd-navigation__complete-btn {
  display: none;
}

.home-body {
  text-align: center;
}

.intro__heading {
  text-align: center;
}

.sd-completedpage > .intro {
  max-width: 560px;
  text-align: left;
  line-height: 2;
  background-color: white;
  padding: 50px;
}

.sd-completedpage {
  align-items: center;
}

/* 換行 */
.sd-html {
  white-space: pre-wrap !important;
}

/* 結尾頁的空白 */
.sd-completedpage:before {
  height: 16px !important;
}
/* .requirement {
  .sv-components-row {
    display: none;
  }

  .container {
    display: flex;
    justify-content: center;
    width: 640px;
    margin: auto;

    .requirementTitle {
      text-align: center;
      margin: 20px;
      font-size: 25px;
    }

    .requirementInput {
      margin: 10px 10px 20px;
      border-radius: 12px;
      border: gray 2px solid;
      padding: 12px;
    }
    .requirementInput:focus {
      outline: #19b394 1px solid;
      border: #19b394 2px solid;
    }

    .requirementInputErr {
      border: red 2px solid;
    }

    .requirementErr {
      color: red;
      margin-bottom: 30px;
    }

    .requirementButtonWrap {
      display: flex;
      justify-content: center;
      margin-top: 25px;
      margin-bottom: 25px;

      .requirementButton {
        background-color: #19b394;
        display: flex;
        color: white;
        padding: 12px 30px;
        border-radius: 100px;
        border: #19b394 1px solid;
        cursor: pointer;
      }
    }

    .loader {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #19b394;
      margin-left: 10px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      animation: spin 1s linear infinite;
    }
  }
} */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noUi-value-sub {
  font-size: 16px !important;
  color: #999 !important;
}

.noUi-marker-normal {
  display: none !important;
}

.noUi-handle {
  cursor: pointer !important;
}

.noUi-origin {
  width: 10% !important;
}

#sv-nav-prev {
  display: none;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.tooltip {
  position: relative;
  display: inline-block;
  width: 20px;
}

.tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -105px;
  font-weight: normal;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

/* mobile 版備註會被卡掉 */
@media (max-width: 576px) {
  .tooltiptext {
    top: auto;
  }

  .tooltip .tooltiptext::after {
    content: none;
  }

  .tooltip {
    position: inherit;
  }
}

.sv-ranking-item__text .sv-string-viewer {
  white-space: pre-wrap;
}

.mystyle {
  color: #19b394 !important;
}

.mybutton {
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  /* font-family: var(--font-family, var(--font-family)); */
  width: 25px;
  height: 25px;
  background-color: rgb(24, 90, 213);
  color: #fff;
  border-radius: 25px;
  border: transparent 1px solid;
}

.sd-html h2 {
  font-size: 1.5rem !important;
}
